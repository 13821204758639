import React from 'react';

import loadLibrary from '../Loadable/library';

const Img = loadLibrary('gatsby-image');

export default function Image(props) {
  const { data } = props;
  return (
    <div>
      <Img
        image={data.image.localFile.childImageSharp.gatsbyImageData}
        alt={data.image.alt_text ? data.image.alt_text : data.image.title}
      />
    </div>
  );
}
